import * as React from 'react';
import { FluidImage } from '../gatsby-image';
import { Link } from 'gatsby';
import _ from 'lodash';
import dateFormat from 'dateformat';
import { render } from 'storyblok-rich-text-react-renderer';
import { sbEditable } from "@storyblok/storyblok-editable";
import {
  PostDetailsWrapper,
  PostTitle,
  PostDate,
  PostPreview,
  PostDescriptionWrapper,
  PostDescription,
  PostTags,
} from './post-details.style';
import PostImage from './PostBlokElement/PostImage'

type PostDetailsProps = {
  title: string;
  date?: string;
  description: any;
  tags?: [];
  className?: string;
  banner_image_postion?: 'left' | 'top';
  banner_image: SFAsset;
  body: SFRichText;
};

const PostDetails: React.FunctionComponent<PostDetailsProps> = ({
  title,
  date,
  description,
  tags,
  className,
  banner_image_postion = "top",
  banner_image,
  body,
  ...props
}) => {

  const addClass: string[] = ['post_details'];

  if (banner_image_postion == 'left') {
    addClass.push('image_left');
  }

  if (className) {
    addClass.push(className);
  }

  // Random Placeholder Color
  const placeholderColors = [
    '#55efc4',
    '#81ecec',
    '#74b9ff',
    '#a29bfe',
    '#ffeaa7',
    '#fab1a0',
    '#e17055',
    '#0984e3',
    '#badc58',
    '#c7ecee',
  ];
  const setColor =
    placeholderColors[Math.floor(Math.random() * placeholderColors.length)];

    const finalBanner_image_position = !!banner_image_postion ? banner_image_postion : 'top';

  return (
    <PostDetailsWrapper {...sbEditable} {...props} className={addClass.join(" ")}>
      {finalBanner_image_position === "left" && (
        <>
          {!!banner_image?.filename && (
            <PostPreview className="post_preview">
              <FluidImage
                blok={banner_image}
                alt={title}
                backgroundColor={setColor}
              />
            </PostPreview>
          )}
        </>
      )}

      {finalBanner_image_position === "top"  && (
        <>
          <PostTitle>{title}</PostTitle>
          <PostDate>{dateFormat(date, 'dd mmm, yyyy')}</PostDate>
        </>
      )}

      {finalBanner_image_position == "top" && (
        <>
          {!!banner_image && (
            <PostPreview className="post_preview">
              <FluidImage
                blok={banner_image}
                alt={title}
                backgroundColor={setColor}
              />
            </PostPreview>
          )}
        </>
      )}
      <PostDescriptionWrapper className="post_des_wrapper">
        {finalBanner_image_position == "left" && (
          <>
            <PostTitle>{title}</PostTitle>
            <PostDate>{dateFormat(date, 'dd mmm, yyyy')}</PostDate>
          </>
        )}

        <PostDescription className="post_des">
          {render(body, {
            blokResolvers: {
              // @ts-ignore
              post_image: PostImage,
            },
          })}
        </PostDescription>

        {tags == null ? null : (
          <PostTags>
            {tags.map((tag, index) => (
              <Link key={index} to={`/tags/${_.kebabCase(tag)}/`}>
                {`#${tag}`}
              </Link>
            ))}
          </PostTags>
        )}
      </PostDescriptionWrapper>
    </PostDetailsWrapper>
  );
};

PostDetails.defaultProps = {
  banner_image_postion: 'top',
};

export default PostDetails;
