

import React from "react";
import { ImageCredits, ImageDescription, ImageWrapper, PostImage } from "./style";
import { FluidImage } from "../../gatsby-image";

const PostImageComponent = (props: {
  credit: string;
  description: string;
  image: SFAsset;
  creditLink: string;
}) => {
  return (
    <PostImage>
    <ImageWrapper>
      <FluidImage blok={props.image} />
    </ImageWrapper>
    <ImageDescription>{props.description}</ImageDescription>
    {props.credit && <ImageCredits href={props.creditLink || ''}>{props.credit}</ImageCredits>}
    </PostImage>
  );
};

export default PostImageComponent;