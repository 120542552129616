import styled from 'styled-components';

export const PostImage = styled.div`
margin: 20px 0;
text-align: center;
font-weight: regular;
font-style: italic;
`;

export const ImageWrapper = styled.aside`
border-radius: 10px;
background-color: grey;
margin-bottom: 8px;
overflow: hidden;
box-shadow: 0px 2px 18px 0px rgba(168,168,168,0.43);
-webkit-box-shadow: 0px 2px 18px 0px rgba(168,168,168,0.43);
-moz-box-shadow: 0px 2px 18px 0px rgba(168,168,168,0.43);
max-height: 512px;
`;


export const ImageDescription = styled.p`
color: #292929;
margin-bottom: 0px !important;
font-size: 15px !important;
;
`
export const ImageCredits = styled.a`
color: #292929 !important;
font-size: 12px;
`