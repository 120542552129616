import { kebabCase } from 'lodash';
import React from 'react';
import setPlaceholderColor from '../../utils/setPlaceholderColor';
import { useStaticQuery, graphql, Link } from 'gatsby';
import FeaturePost from '../../components/feature-post/feature-post';
import useInstagram from '../../hooks/use-instagram';
import {
  SidebarWrapper,
  SidebarWidget,
  WidgetTitle,
  TagItem,
  InstagramWrapper,
  InstagramPhoto,
} from './style';
import parseContent from '../../utils/parseContent';

type SidebarProps = {};

const Sidebar: React.FunctionComponent<SidebarProps> = () => {
  const instagramPhotos = useInstagram();
  const Data = useStaticQuery(graphql`
    query {
      latestPosts: allStoryblokEntry(
        filter: {field_component: {eq: "blog_post"}}
        sort: {order: DESC, fields: published_at}
        limit: 5
      ) {
        nodes {
          name
          full_slug
          published_at
          id
          tag_list
          content
        }

      }
      tagCount: allStoryblokTag {
        group(field: id) {
          nodes {
            taggings_count
            name
          }
        }
      }
      promotions: allStoryblokEntry(
        filter: {field_component: {eq: "promotions"}}
        sort: {fields: published_at, order: DESC}
        limit: 1
      ) {
        nodes {
          name
          content
        }
      }
    }
  `);

  const Posts = Data.latestPosts.nodes;
  const Tags = Data.tagCount.group;
  const promotion = Data.promotions.nodes[0];
  const promotionContent = parseContent(promotion.content);
  
  return (
    <SidebarWrapper>
      <SidebarWidget>
        <WidgetTitle>{promotion.name}</WidgetTitle>
        <a
          href={promotionContent.link.url}
          aria-label="Get StoryHub"
          target="_blank"
        >
          <img src={promotionContent.banner_image.filename} alt="Get StoryHub" />
        </a>
      </SidebarWidget>

      <SidebarWidget>
        <WidgetTitle>{Posts.length > 1 ? 'Latest Posts' : 'Latest Post'}</WidgetTitle>
        {Posts.map((post: any) => {
          const content = parseContent(post.content);
          // Random Placeholder Color
          const color = setPlaceholderColor();

          return (
            <FeaturePost
              key={post.id}
              title={post.name}
              image={content.banner_image}
              url={post.full_slug}
              tags={post.tag_list}
              placeholderBG={color}
            />
          );
        })}
      </SidebarWidget>

      <SidebarWidget>
        <WidgetTitle>Tags</WidgetTitle>
        {Tags.map((tag: any) => (
          <TagItem key={tag?.nodes[0].name}>
            <span>#</span>
            <Link to={`/tags/${kebabCase(tag?.nodes[0].name)}/`}>
              {tag?.nodes[0].name} <span>({tag?.nodes[0].taggings_count})</span>
            </Link>
          </TagItem>
        ))}
      </SidebarWidget>

      {/* <SidebarWidget>
        <WidgetTitle>Instagram</WidgetTitle>
        {instagramPhotos ? (
          <InstagramWrapper>
            {instagramPhotos.slice(0, 4).map((node: any) => {
              // Random Placeholder Color

              const color = setPlaceholderColor();

              return (
                <InstagramPhoto key={node?.id}>
                  <a href={node?.permalink} target="_blank">
                    <img
                      src={node?.media_url}
                      alt="Instagram Photo"
                      style={{ backgroundColor: color }}
                    />
                  </a>
                </InstagramPhoto>
              );
            })}
          </InstagramWrapper>
        ) : (
          ""
        )}
      </SidebarWidget> */}
    </SidebarWrapper>
  );
};

export default Sidebar;
