import React from 'react';
import { graphql, Link } from 'gatsby';
import _ from 'lodash';
import urljoin from 'url-join';
// import { DiscussionEmbed } from 'disqus-react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PostCard from '../components/post-card/post-card';
import PostDetails from '../components/post-details/post-details';
import Newsletter from '../components/newsletter/newsletter';
import Sidebar from '../containers/sidebar';
import useStoryblok from "../utils/storyblok";
import parseContent from '../utils/parseContent';
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  RedditShareButton,
} from 'react-share';
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoPinterest,
  IoLogoReddit,
} from 'react-icons/io';
import {
  BlogPostDetailsWrapper,
  RelatedPostWrapper,
  RelatedPostItems,
  RelatedPostTitle,
  RelatedPostItem,
  BlogPostFooter,
  PostShare,
  PostTags,
  BlogPostFooterLeftSectionWrapper,
  // BlogPostComment,
  BlogDetailsContent,
} from './templates.style';
import setPlaceholderColor from '../utils/setPlaceholderColor';
import Intro from "../components/intro";
import withSbEditable from '../components/withSBeditable/withSBeditable';

type BlogPostType = SBlok & {
  published_at?: string;
  name: string;
  full_slug: string;
  tag_list: string[] | null;
  content: {
    description: string;
    banner_image: SFAsset;
    banner_image_position: 'top' | 'left'
    body: SFRichText

  }
}

const BlogPostTemplate = (props: any) => {
  const blogPost: BlogPostType = useStoryblok(props.data.blogPost.edges[0]?.node, props.location);
  const relatedBlogPosts: BlogPostType[] = props.data.relatedBlogPosts.nodes;
  const newsLetter = parseContent(props.data.newsletter.nodes[0].content);

  const title = blogPost.name;
  const slug = blogPost.full_slug;
  const siteUrl = props.data.site.siteMetadata.siteUrl;
  const shareUrl = urljoin(siteUrl, slug);


  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: { identifier: blogPost.id, title, url: shareUrl },
  };


  const authorInfo = blogPost.content?.author?.[0];

  
  return (
    <Layout>
      <SEO title={blogPost.name} description={blogPost.content.description} />
      <BlogPostDetailsWrapper>
        <BlogDetailsContent>
          <PostDetails
            title={blogPost.name}
            date={blogPost.published_at}
            {...blogPost.content}
          />

          <BlogPostFooter>
            <BlogPostFooterLeftSectionWrapper>
              {blogPost.tag_list == null ? null : (
                <PostTags className="post_tags">
                  Tags:&nbsp; 
                  {blogPost.tag_list.map((tag: string) => (
                    <Link key={tag} to={`/tags/${_.kebabCase(tag)}/`}>
                      {`#${tag}`}
                    </Link>
                  ))}
                </PostTags>
              )}
              <PostShare>
                <span>Share this post:</span>
                <FacebookShareButton
                  url={shareUrl}
                  quote={blogPost.content.description}
                >
                  <IoLogoFacebook />
                </FacebookShareButton>
                <TwitterShareButton url={shareUrl} title={title}>
                  <IoLogoTwitter />
                </TwitterShareButton>
                <PinterestShareButton url={shareUrl} media={urljoin(siteUrl)}>
                  <IoLogoPinterest />
                </PinterestShareButton>
                <RedditShareButton url={shareUrl} title={`${blogPost.name}`}>
                  <IoLogoReddit />
                </RedditShareButton>
              </PostShare>
            </BlogPostFooterLeftSectionWrapper>
            {!!authorInfo && <Intro {...authorInfo} picSize="small"/>}

          </BlogPostFooter>

          {/* <BlogPostComment>
            <DiscussionEmbed {...disqusConfig} />
          </BlogPostComment> */}
        </BlogDetailsContent>
        <Sidebar />
      </BlogPostDetailsWrapper>

      {!!relatedBlogPosts.length && (
        <RelatedPostWrapper>
          <RelatedPostTitle>Related Posts</RelatedPostTitle>
          <RelatedPostItems>
            {relatedBlogPosts.map((post: any) => {
              const postContent = parseContent(post.content);
              // Random Placeholder Color
              const color = setPlaceholderColor();
              return (
                <RelatedPostItem key={post.id}>
                  <PostCard
                    title={post.name}
                    url={post.full_slug}
                    image={postContent.banner_image}
                    tags={post.tag_list}
                    placeholderBG={color}
                  />
                </RelatedPostItem>
              );
            })}
          </RelatedPostItems>
        </RelatedPostWrapper>
      )}
      <Newsletter {...newsLetter} />
    </Layout>
  );
};

export default withSbEditable(BlogPostTemplate);

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $tag: [String],) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    blogPost: allStoryblokEntry(
      filter: {full_slug: {eq: $slug}}
    ) {
      edges {
        node {
          id
          tag_list
          full_slug
          content
          published_at
          name
        }
      }
    }
    relatedBlogPosts: allStoryblokEntry(
      filter: {tag_list: {in: $tag}}
      sort: {order: DESC, fields: published_at}
    ) {
      nodes {
        id
        name
        tag_list
        full_slug
        content
        published_at
      }
    }
    newsletter: allStoryblokEntry(filter: {field_component: {eq: "newsletter"}}) {
      nodes {
        id
        content
      }
    }
  }
`;

